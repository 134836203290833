/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Layout as AntLayout, ConfigProvider } from "antd"
import "./layout.css"
import "../theme/vars.less"

import "antd/dist/antd.dark.css"
// @ts-ignore
import backgroundCover from "../images/header_background.jpg"
// @ts-ignore
import csLogo from "../images/logo.png"
import { CartProvider } from "./cart-context"
import { Footer } from "./footer"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      # background: file(relativePath: { eq: "header_background.jpg" }) {
      #   childImageSharp {
      #     gatsbyImageData(layout: FIXED, breakpoints)
      #   }
      # }

      # logo: file(relativePath: { eq: "logo.png" }) {
      #   childImageSharp {
      #     gatsbyImageData(layout: FIXED)
      #   }
      # }
    }
  `)

  return (
    <>
      <CartProvider>
        <AntLayout>
          <div
            style={{
              width: "100%",
              height: "800px",
              maxHeight: "50vh",
              position: "relative",
              zIndex: 1,
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "0px",
                right: "0px",
                top: "50px",
                zIndex: 2,
                textAlign: "center",
              }}
            >
              <StaticImage alt="Logo Casual Spirit" src="../images/logo.png" />
            </div>
            <StaticImage
              alt="Ville de nuit - fond du site."
              src="../images/header_background.jpg"
              transformOptions={{ fit: "cover" }}
            />
          </div>
          <div
            style={{
              margin: `0 auto`,
              width: 1200,
              maxWidth: "100%",
              padding: `1rem 1.0875rem 1.45rem`,
              zIndex: 2,
            }}
          >
            <main>{children}</main>
            <Footer />
          </div>
        </AntLayout>
      </CartProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
