import * as React from "react"
import { CartActions, useCart } from "./cart-context"
// @ts-ignore
import csLogo from "../images/logo.png"
import { Col, Row, Divider, Card } from "antd"
import {
  FacebookFilled,
  InstagramFilled,
  BankOutlined,
  CommentOutlined,
  LikeOutlined,
} from "@ant-design/icons"
import { Link } from "gatsby"

export const Footer = () => {
  const cartContext = useCart()

  React.useEffect(() => {
    if (!cartContext) {
      return
    }
    // @ts-ignore
    const store = Snipcart.store.getState()

    cartContext.dispatch({
      type: CartActions.SetItems,
      payload: store.cart.items.count,
    })
  }, [])

  return (
    <footer
      style={{
        width: "100%",
        marginTop: "20px",
      }}
    >
      <Divider />
      <Row gutter={8}>
        <Col lg={8} md={8} sm={12} xs={24}>
          <Card
            style={{
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            <BankOutlined style={{ fontSize: "40px", color: "grey" }} />
            <p>Transaction sécurisée</p>
          </Card>
        </Col>
        <Col lg={8} md={8} sm={12} xs={24}>
          <Card
            style={{
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            <CommentOutlined style={{ fontSize: "40px", color: "grey" }} />
            <p>Service client réactif</p>
          </Card>
        </Col>
        <Col lg={8} md={8} sm={12} xs={24}>
          <Card
            style={{
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            <LikeOutlined style={{ fontSize: "40px", color: "grey" }} />
            <p>Satisfait ou remboursé</p>
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} style={{ textAlign: "center" }}>
          <h5>Suivez nous !</h5>
          <div>
            <FacebookFilled style={{ fontSize: "30px" }} />
            <InstagramFilled style={{ fontSize: "30px" }} />
          </div>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <img
            src={csLogo}
            style={{
              width: "100%",
              maxWidth: "300px",
              margin: "auto",
            }}
          />
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <Link to="/mentions-legales">Mentions Légales</Link>
          {" - "}
          <Link to="/politique-de-confidentialite">
            Politique de confidentialité
          </Link>
          {" - "}
          <Link to="/cgv">Conditions générales de vente</Link>
        </Col>
      </Row>
    </footer>
  )
}
