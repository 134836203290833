// src/count-context.js
import * as React from "react"

enum CartActions {
  SetTotal = "set-total",
  SetItems = "set-items",
}
type CartState = { total: number; items: number }
type Action = { type: CartActions; payload: number }
type Dispatch = (action: Action) => void

const CartContext = React.createContext<
  { state: CartState; dispatch: Dispatch } | undefined
>(undefined)

function cartReducer(state: CartState, action: Action) {
  switch (action.type) {
    case CartActions.SetTotal: {
      return { ...state, total: action.payload }
    }
    case CartActions.SetItems: {
      return { ...state, items: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function CartProvider({ children }) {
  const [state, dispatch] = React.useReducer<
    (prev: CartState, action: Action) => CartState,
    CartState
  >(
    cartReducer,
    {
      total: 0,
      items: 0,
    },
    arg => {
      return arg
    }
  )
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

function useCart() {
  const context = React.useContext(CartContext)
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider")
  }
  return context
}

export { CartProvider, CartActions, CartContext, useCart  }
